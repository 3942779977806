import moment from "moment-timezone";

import { DATE_FORMAT } from "constants/common";
import { NOTIFICATION_TYPE, SHIFT_STATUS } from "./constants";

export const getNotificationDesc = (notification) => {
  let text = "";
  const {
    type,
    status,
    requested_shift_position,
    requester_shift_position,
    requester_provider_name,
    requested_shift_date,
    requester_shift_date,
  } = notification;

  const formattedRequestedShiftDate =
    moment(requested_shift_date).format(DATE_FORMAT);
  const formattedRequesterShiftDate =
    moment(requester_shift_date).format(DATE_FORMAT);

  if (type === NOTIFICATION_TYPE.SHIFT_SWAP) {
    switch (status) {
      case SHIFT_STATUS.PENDING:
        text = `<b>${requester_provider_name}</b> has requested to swap your <b>${requested_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b> with their <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b>. This request is pending approval.`;
        break;
      case SHIFT_STATUS.ACCEPTED:
        text = `You have accepted <b>${requester_provider_name}</b>'s request to swap your <b>${requested_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b> with their <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b>.`;
        break;
      case SHIFT_STATUS.REJECTED:
        text = `You have rejected <b>${requester_provider_name}</b>'s request to swap your <b>${requested_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b> with their <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b>.`;
        break;
      default:
        text = `Status unknown for <b>${requester_provider_name}</b>'s swap request of your <b>${requested_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b> with their <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b>.`;
        break;
    }
  } else if (type === NOTIFICATION_TYPE.SHIFT_TRANSFER) {
    switch (status) {
      case SHIFT_STATUS.PENDING:
        text = `<b>${requester_provider_name}</b> wants to transfer their <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b> to you. This transfer is pending approval.`;
        break;
      case SHIFT_STATUS.ACCEPTED:
        text = `You have accepted the transfer of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b> to you.`;
        break;
      case SHIFT_STATUS.REJECTED:
        text = `You have rejected the transfer of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b> to you.`;
        break;
      default:
        text = `Status unknown for the transfer of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequesterShiftDate}</b> to you.`;
        break;
    }
  } else if (type === NOTIFICATION_TYPE.PROVIDER_SHIFT_ASSIGNMENT) {
    switch (status) {
      case SHIFT_STATUS.PENDING:
        text = `<b>${requester_provider_name}</b> wants to assign <b>${requester_shift_position}</b> shift to himself on ${formattedRequestedShiftDate}. <br/> <b> This assignment is pending approval.`;
        break;
      case SHIFT_STATUS.ACCEPTED:
        text = `You have accepted the assignment of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b>.`;
        break;
      case SHIFT_STATUS.REJECTED:
        text = `You have rejected the assignment of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b>.`;
        break;
      default:
        text = `Status unknown for the assignment of <b>${requester_provider_name}</b>'s <b>${requester_shift_position}</b> shift on <b>${formattedRequestedShiftDate}</b>.`;
        break;
    }
  }

  return text;
};
