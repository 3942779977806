export const NOTIFICATION_ACTION = {
  ACCEPT: "accept",
  REJECT: "reject",
};

export const NOTIFICATION_TYPE = {
  SHIFT_TRANSFER: "TRANSFER",
  SHIFT_SWAP: "SWAP",
  PROVIDER_SHIFT_ASSIGNMENT: "Provider shift assignment"
};

export const NOTIFICATION_TITLE = {
  [NOTIFICATION_TYPE.SHIFT_SWAP]: "Swap Request",
  [NOTIFICATION_TYPE.SHIFT_TRANSFER]: "Transfer Request",
  [NOTIFICATION_TYPE.PROVIDER_SHIFT_ASSIGNMENT]: "Provider self assignment request"
};

export const SHIFT_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};
