import { fetchToken } from "@firebase/utils";
import { LOGIN } from "constants/apiEndpoints";
import { AUTH_SOURCE, AXIOS_METHODS } from "constants/common";
import { AbilityContext } from "contexts/can";
import { useAxios } from "hooks";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onPermissionDenied,
  setToken,
} from "services/redux/slices/notification";

import { processUserLogin } from "services/redux/thunk/user";

const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);

  const {
    triggerApi: triggerLoginApi,
    response: loginApiResponse,
    success: loginApiSuccess,
    loading: loginApiLoading,
  } = useAxios({
    method: AXIOS_METHODS.POST,
    url: LOGIN,
    onInit: false,
  });

  const handleNavigation = (to) => {
    navigate(to);
  };

  const handleLogin = async (values) => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    console.log("isIOS", isIOS);
    let fcmToken = null;
    if (isIOS) {
      fcmToken = "or-board-ios";
    } else {
      fcmToken = await fetchToken();
    }

    if (fcmToken !== null) {
      dispatch(setToken(fcmToken));
    } else {
      dispatch(onPermissionDenied());
    }
    if (fcmToken) {
      values.fcmToken = fcmToken;
    }

    triggerLoginApi({
      data: values,
    });
  };

  useEffect(() => {
    if (loginApiSuccess) {
      dispatch(
        processUserLogin({
          apiResponse: loginApiResponse,
          type: AUTH_SOURCE.LOGIN,
          ability,
        })
      );
    }
  }, [loginApiSuccess, loginApiResponse, dispatch, ability]);

  return { handleNavigation, handleLogin, loginApiLoading };
};

export default useLogin;
