export const ROLES = {
  ADMIN: "admin",
  RCM: "rcm",
  CLINICAL_LEADERSHIP: "clinical_leadership",
  ELT: "elt",
  PROVIDER: "provider",
  TEST_PROVIDER: "test_provider",
  EXTERNAL_CODER: "externalcoder",
  ENROLLMENT: "enrollment",
  HOSPITAL_MEMBER: "hospital_member",
  ENROLMENT_RCM:"enrollment+rcm"
};

export const DEFAULT_ACTIONS = {
  VIEW: "view",
  DELETE: "delete",
  CREATE: "create",
  UPDATE: "update",
};

export const MODULES = {
  BOARD: "board",
  EOD_CONFIRMATION: "eodConfirmation",
  PROCEDURES: "procedures",
  BLOCK_SCHEDULES: "blockSchedules",
  GO_HOME_LIST: "goHomeList",
  GAS_SHIFTS_CALENDAR: "gasShiftsCalendar",
  FORECAST_DATA: "forecastData",
};

export const SUB_ACTIONS = {
  [MODULES.GAS_SHIFTS_CALENDAR]: {
    ASSIGN_PROVIDERS: "assign_providers",
    SWAP_SHIFT: "swap_shift",
  },
  [MODULES.BOARD]: {
    APPLY_FILTERS: "apply_filters",
  },
};

export const ADMIN_PERMISSIONS = {
  [MODULES.BOARD]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    DEFAULT_ACTIONS.DELETE,
    SUB_ACTIONS[MODULES.BOARD].APPLY_FILTERS,
  ],
  [MODULES.EOD_CONFIRMATION]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  [MODULES.PROCEDURES]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.CREATE,
    DEFAULT_ACTIONS.UPDATE,
  ],
  [MODULES.BLOCK_SCHEDULES]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.CREATE,
    DEFAULT_ACTIONS.DELETE,
    DEFAULT_ACTIONS.UPDATE,
  ],
  [MODULES.GO_HOME_LIST]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  [MODULES.GAS_SHIFTS_CALENDAR]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    SUB_ACTIONS[MODULES.GAS_SHIFTS_CALENDAR].ASSIGN_PROVIDERS,
  ],
  [MODULES.FORECAST_DATA]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.CREATE,
    DEFAULT_ACTIONS.DELETE,
    DEFAULT_ACTIONS.UPDATE,
  ],
};

export const CLINICAL_LEADERSHIP_PERMISSIONS = {
  [MODULES.BOARD]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    DEFAULT_ACTIONS.DELETE,
    SUB_ACTIONS[MODULES.BOARD].APPLY_FILTERS,
  ],
  [MODULES.EOD_CONFIRMATION]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  [MODULES.PROCEDURES]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.CREATE,
    DEFAULT_ACTIONS.UPDATE,
  ],
  [MODULES.BLOCK_SCHEDULES]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.CREATE,
    DEFAULT_ACTIONS.DELETE,
    DEFAULT_ACTIONS.UPDATE,
  ],
  [MODULES.GO_HOME_LIST]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  [MODULES.GAS_SHIFTS_CALENDAR]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    SUB_ACTIONS[MODULES.GAS_SHIFTS_CALENDAR].ASSIGN_PROVIDERS,
  ],
};

export const PROVIDER_PERMISSIONS = {
  [MODULES.BOARD]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    DEFAULT_ACTIONS.DELETE,
    SUB_ACTIONS[MODULES.BOARD].APPLY_FILTERS,
  ],
  // [MODULES.EOD_CONFIRMATION]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  // [MODULES.PROCEDURES]: [
  //   DEFAULT_ACTIONS.VIEW,
  //   DEFAULT_ACTIONS.CREATE,
  //   DEFAULT_ACTIONS.UPDATE,
  // ],
  // [MODULES.BLOCK_SCHEDULES]: [
  //   DEFAULT_ACTIONS.VIEW,
  //   DEFAULT_ACTIONS.CREATE,
  //   DEFAULT_ACTIONS.DELETE,
  //   DEFAULT_ACTIONS.UPDATE,
  // ],
  [MODULES.GAS_SHIFTS_CALENDAR]: [
    DEFAULT_ACTIONS.VIEW,
    SUB_ACTIONS[MODULES.GAS_SHIFTS_CALENDAR].SWAP_SHIFT,
  ],
};

export const ENROLMENT_RCM_PERMISSIONS = {
  [MODULES.BOARD]: [
    DEFAULT_ACTIONS.VIEW,
    DEFAULT_ACTIONS.UPDATE,
    DEFAULT_ACTIONS.DELETE,
    SUB_ACTIONS[MODULES.BOARD].APPLY_FILTERS,
  ],
  // [MODULES.EOD_CONFIRMATION]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
  // [MODULES.PROCEDURES]: [
  //   DEFAULT_ACTIONS.VIEW,
  //   DEFAULT_ACTIONS.CREATE,
  //   DEFAULT_ACTIONS.UPDATE,
  // ],
  // [MODULES.BLOCK_SCHEDULES]: [
  //   DEFAULT_ACTIONS.VIEW,
  //   DEFAULT_ACTIONS.CREATE,
  //   DEFAULT_ACTIONS.DELETE,
  //   DEFAULT_ACTIONS.UPDATE,
  // ],
  [MODULES.GAS_SHIFTS_CALENDAR]: [
    DEFAULT_ACTIONS.VIEW,
    SUB_ACTIONS[MODULES.GAS_SHIFTS_CALENDAR].SWAP_SHIFT,
  ],
};

export const HOSPITAL_MEMBER_PERMISSIONS = {
  [MODULES.BOARD]: [DEFAULT_ACTIONS.VIEW],
};

export const [
  RCM_PERMISSIONS,
  TEST_PROVIDER_PERMISSIONS,
  EXTERNAL_CODER_PERMISSIONS,
  ENROLLMENT_PERMISSIONS,
  ELT_PERMISSIONS,
] = [
  [ROLES.RCM],
  [ROLES.TEST_PROVIDER],
  [ROLES.EXTERNAL_CODER],
  [ROLES.ENROLLMENT],
  [ROLES.ELT],
].map((_) => {
  return {
    [MODULES.BOARD]: [
      DEFAULT_ACTIONS.VIEW,
      DEFAULT_ACTIONS.UPDATE,
      DEFAULT_ACTIONS.DELETE,
      SUB_ACTIONS[MODULES.BOARD].APPLY_FILTERS,
    ],
    [MODULES.EOD_CONFIRMATION]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
    [MODULES.PROCEDURES]: [
      DEFAULT_ACTIONS.VIEW,
      DEFAULT_ACTIONS.CREATE,
      DEFAULT_ACTIONS.UPDATE,
    ],
    [MODULES.BLOCK_SCHEDULES]: [
      DEFAULT_ACTIONS.VIEW,
      DEFAULT_ACTIONS.CREATE,
      DEFAULT_ACTIONS.DELETE,
      DEFAULT_ACTIONS.UPDATE,
    ],
    [MODULES.GO_HOME_LIST]: [DEFAULT_ACTIONS.VIEW, DEFAULT_ACTIONS.UPDATE],
    [MODULES.GAS_SHIFTS_CALENDAR]: [
      DEFAULT_ACTIONS.VIEW,
      DEFAULT_ACTIONS.UPDATE,
      SUB_ACTIONS[MODULES.GAS_SHIFTS_CALENDAR].ASSIGN_PROVIDERS,
    ],
  };
});

export const ROLES_PERMISSIONS = {
  [ROLES.ELT]: ELT_PERMISSIONS,
  [ROLES.RCM]: RCM_PERMISSIONS,
  [ROLES.ADMIN]: ADMIN_PERMISSIONS,
  [ROLES.PROVIDER]: PROVIDER_PERMISSIONS,
  [ROLES.ENROLLMENT]: ENROLLMENT_PERMISSIONS,
  [ROLES.TEST_PROVIDER]: PROVIDER_PERMISSIONS,
  [ROLES.EXTERNAL_CODER]: EXTERNAL_CODER_PERMISSIONS,
  [ROLES.HOSPITAL_MEMBER]: HOSPITAL_MEMBER_PERMISSIONS,
  [ROLES.CLINICAL_LEADERSHIP]: CLINICAL_LEADERSHIP_PERMISSIONS,
  [ROLES.ENROLMENT_RCM]: ENROLMENT_RCM_PERMISSIONS,
};
