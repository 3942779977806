import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_NOTIFICATIONS } from "constants/apiEndpoints";
import { setNotifications } from "../slices/notification";
import axiosInstance from "services/api/axiosInstance";

export const processNotifications = createAsyncThunk(
  "notification/fetch",
  async (_, { dispatch, getState }) => {
    const providerId = getState().user?.user?.provider_id;
    const role = getState().user?.user?.role;
    const userId = getState().user?.user?.id;
    try {
      const getNotificationsApiResponse = await axiosInstance.get(
        GET_NOTIFICATIONS,
        {
          params: {
            userId,
            providerId,
            ...(role === 'admin' ? {notification_type: "full_notification"} : {})
          },
        }
      );

      if (getNotificationsApiResponse?.data?.success) {
        dispatch(setNotifications(getNotificationsApiResponse?.data?.data));
      }
    } catch (err) {
      console.error(err);
    }
  }
);
