import { MAANGE_SWAP_OR_TRANSFER, MANAGE_PROVIDER_SHIFT_ASSIGNMENT } from "constants/apiEndpoints";
import { AXIOS_METHODS } from "constants/common";
import { useAxios } from "hooks";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processNotifications } from "services/redux/thunk/notification";
import { NOTIFICATION_TYPE } from "./constants";

const useNotificationsDrawer = () => {
  const dispatch = useDispatch();

  const [selectedNotification, setSelectedNotification] = useState(null);

  const {
    triggerApi: triggerManageShiftRequestApi,
    loading: manageShiftRequestLoading,
  } = useAxios({
    url: MAANGE_SWAP_OR_TRANSFER,
    method: AXIOS_METHODS.POST,
    onInit: false,
  });

  const {
    triggerApi: triggerProviderShiftAssignmentRequestApi,
    loading: providerShiftAssignmentRequestLoading,
  } = useAxios({
    url: MANAGE_PROVIDER_SHIFT_ASSIGNMENT,
    method: AXIOS_METHODS.POST,
    onInit: false,
  });

  const notifications = useSelector(
    (state) => state.notification.notifications
  );

  const timezone = useSelector((state) => state.user.user.timezone);

  const handleShiftRequest = (notification, action) => async () => {
    setSelectedNotification(notification);
    let response;
    if(notification.type == NOTIFICATION_TYPE.PROVIDER_SHIFT_ASSIGNMENT){
      response = await triggerProviderShiftAssignmentRequestApi({
        data: { requestId: notification?.id, action },
      });
    } else{
      response = await triggerManageShiftRequestApi({
        data: { requestId: notification?.id, action },
      });
    }
    if (response?.success) {
      dispatch(processNotifications());
    }
  };

  const getIfShiftActionInProgress = (notification) =>
    notification.id === selectedNotification?.id && (manageShiftRequestLoading || providerShiftAssignmentRequestLoading);


  return {
    timezone,
    notifications,
    handleShiftRequest,
    manageShiftRequestLoading,
    getIfShiftActionInProgress,
  };
};

export default useNotificationsDrawer;
