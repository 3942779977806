import { BellOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Grid, Select, Tag } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSocket } from "contexts/socket";
import { getDirectionallyFilteredButtons } from "layouts/DefaultLayout/utils";
import { onLogout } from "services/redux/slices/user";
import { Link } from "react-router-dom";
import { processNotifications } from "services/redux/thunk/notification";

const { useBreakpoint } = Grid;

const usePageHeader = () => {
  const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] =
    useState(false);

  const dispatch = useDispatch();

  const screens = useBreakpoint();

  const { disconnect: disconnectSocket } = useSocket();

  const {
    user: { user },
    layout: { dashboardLayout },
  } = useSelector((state) => state);

  const handleLogout = useCallback(() => {
    disconnectSocket();
    localStorage.clear();
    dispatch(onLogout());
  }, [dispatch, disconnectSocket]);

  const toggleNotificationDrawer = () => {
    dispatch(processNotifications());
    setIsNotificationDrawerVisible((curr) => !curr);
  };

  const ddItems = useMemo(
    () => [
      {
        label: <Link to={"/profile"}>Profile</Link>,
        key: "1",
        icon: <UserOutlined />,
      },
      {
        type: "divider",
      },
      {
        label: "Logout",
        danger: true,
        key: "2",
        onClick: handleLogout,
        icon: <LogoutOutlined />,
      },
    ],
    [handleLogout]
  );

  const [actionButtonsLeftJsx, actionButtonsRightJsx] = useMemo(() => {
    const [leftButtons, rightButtons] = getDirectionallyFilteredButtons(
      dashboardLayout?.buttons
    );

    const requiredActions = [
      <Button
        key={"notification"}
        type="text"
        onClick={toggleNotificationDrawer}
        icon={<BellOutlined className="text-16" />}
      />,
    ];

    return [
      <div className="action-buttons-wrapper">
        {leftButtons.map(({ title, direction, ...rest }, index) => (
          <Button key={index} {...rest}>
            {title}
          </Button>
        ))}
      </div>,
      <div className="action-buttons-wrapper">
        {requiredActions}
        {rightButtons.map(({ title, direction, ...rest }, index) => (
          <Button key={index} {...rest}>
            {title}
          </Button>
        ))}
      </div>,
    ];
  }, [dashboardLayout?.buttons]);

  const dashboardTagsJsx = useMemo(
    () => (
      <div className="dashboard-tags-wrapper">
        {dashboardLayout.tags.map(({ title, ...rest }, index) => (
          <Tag {...rest} key={index}>
            {title}
          </Tag>
        ))}
      </div>
    ),
    [dashboardLayout?.tags]
  );

  const dashboardSelectsJsx = useMemo(
    () => (
      <div className="dashboard-selects-wrapper">
        {dashboardLayout.selects.map((props, index) => (
          <Select
            {...props}
            key={index}
            showSearch
            allowClear={props?.mode === 'multiple' ? true : false}
            filterOption={(inputValue, option) => {
              const regex = new RegExp(inputValue, "i");
              return regex.test(option?.name || "");
            }}
          />
        ))}
      </div>
    ),
    [dashboardLayout?.selects]
  );

  return {
    handleLogout,
    ddItems,
    user,
    dashboardLayout,
    dashboardTagsJsx,
    dashboardSelectsJsx,
    actionButtonsLeftJsx,
    actionButtonsRightJsx,
    screens,
    timezone: user?.timezone,
    toggleNotificationDrawer,
    isNotificationDrawerVisible,
    onDateChange: dashboardLayout.boardInfo.onDateChange,
  };
};

export default usePageHeader;
